import http from '@/utils/httpRequest'

// 根据项目获取数据
export function getList(projectId) {
    return http({
        url: http.adornUrl(`/hbi/project/getInfo/${projectId}`),
        method: 'get',
    })
}

// 删除 菜单
export function deleteMenuData(id) {
    return http({
        url: http.adornUrl(`/hbi/hbiprojectmenu/delete/${id}`),
        method: "post"
    })
}


// 根据ID 获取 菜单
export function getById(id) {
    return http({
        url: http.adornUrl(`/hbi/hbiprojectmenu/info/${id}`),
        method: "get"
    })
}

// 更新或新增 菜单
export function saveOrUpdate(data) {
    return http({
        url: http.adornUrl(
            `/hbi/hbiprojectmenu/${!data.menuId ? "save" : "update"}`
        ),
        method: "post",
        data: http.adornData(data)
    })
}

 // 根据项目ID获取所有的菜单
export function selectList(projectId) {
    return http({
        url: http.adornUrl(`/hbi/hbiprojectmenu/select/${projectId}`),
        method: "get",
    })
}

// 列表获取已发布的pc端报表
export function getPcList() {
    return http({
        url: http.adornUrl('/hbi/hbiprojectmenu/pcList'),
        method: 'get',
        params: http.adornParams()
    })
}

// 删除 用户
export function deleteUserData(data) {
    return http({
        url: http.adornUrl(`/hbi/project/relation/user/delete/${data.projectId}`),
        method: "post",
        data: http.adornData([data.userIds], false)
    })
}

// 新增 用户
export function saveOrUpdateUser(data) {
    return http({
        url: http.adornUrl(
            `/hbi/project/relation/user/save/${data.projectId}`
        ),
        method: "post",
        data: http.adornData([data.userIds], false)
    })
}

// 删除 app报表
export function deleteAppData(data) {
    return http({
        url: http.adornUrl(`/hbi/project/relation/report/delete/${data.projectId}`),
        method: "post",
        data: http.adornData([data.reportId], false)
    })
}

// 更新或新增 app报表
export function saveOrUpdateApp(data) {
    return http({
        url: http.adornUrl(
            `/hbi/project/relation/report/save/${data.projectId}`
        ),
        method: "post",
        data: http.adornData([data.reportId], false)
    })
}

// 获取已发布的app列表
export function getAppList() {
    return http({
        url: http.adornUrl('/hbi/hbiprojectmenu/appList'),
        method: 'get',
        params: http.adornParams()
    })
}

// 根据项目ID获取大屏列表
export function getBscreenList(projectId) {
    return http({
        url: http.adornUrl(`/hbi/project/relation/bscreen/list/${projectId}`),
        method: 'get'
    })
}

// 更新或新增 大屏
export function saveOrUpdateBscrren(data) {
    return http({
        url: http.adornUrl(
            `/hbi/project/relation/bscreen/save/${data.projectId}`
        ),
        method: "post",
        data: http.adornData([data.bscreenId], false)
    })
}

// 删除 大屏
export function deleteBscreenData(data) {
    return http({
        url: http.adornUrl(`/hbi/project/relation/bscreen/delete/${data.projectId}`),
        method: "post",
        data: http.adornData([data.bscreenId], false)
    })
}

// 列表获取 所有项目
export function getProjectList(params) {
    return http({
        url: http.adornUrl('/hbi/project/list'),
        method: 'get',
        params: params
    })
}

// 列表获取 根据用户获取所有项目
export function getListAll(params) {
    return http({
        url: http.adornUrl('/hbi/project/listAll'),
        method: 'get',
        params: params
    })
}

// 删除数据 项目
export function deleteProjectData(ids = []) {
    return http({
        url: http.adornUrl("/hbi/project/delete"),
        method: "post",
        data: http.adornData(ids, false)
    })
}

// 根据ID 获取
export function getByIdProject(id) {
    return http({
        url: http.adornUrl(`/hbi/project/info/${id}`),
        method: "get"
    })
}

// 更新或新增 项目
export function saveOrUpdateProject(data) {
    return http({
        url: http.adornUrl(
            `/hbi/project/${!data.id ? "save" : "update"}`
        ),
        method: "post",
        data: http.adornData(data)
    })
}

// 项目修改操作前密码校验
export function validatePassword(data) {
    return http({
        url: http.adornUrl(
            `/hbi/project/validate`
        ),
        method: "post",
        data: http.adornData(data)
    })
}

// 修改密码
export function updatePassword(data) {
    return http({
        url: http.adornUrl(
            `/hbi/project/updatePassword`
        ),
        method: "post",
        data: http.adornData(data)
    })
}